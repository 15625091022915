import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik'; // Sửa lại import
import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import "../asset/scss/component/login.scss";
import { useSelector, useDispatch } from 'react-redux';
import { loginFun, registerFun } from "../service/callAPi";
import ImgBannerLogin from "../asset/image/background-not-logged-in-vn.webp";
import ImgBannerRegister from "../asset/image/background-vn (1).webp";
import { useTranslation } from 'react-i18next';

function Login() {
  const { t } = useTranslation(); // Hook để lấy hàm dịch
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isDispatch = useSelector((state) => state.reducers.isDispatch);
  const [type, setType] = useState(searchParams.get('type') === 'true' ? true : false);
  const [toggle, setToggle] = useState(false);

  const schema = yup.object().shape({
    username: yup.string().required(t('username_required')),
    password: yup.string().required(t('password_required')),
  });

  const postAPI = (values, { resetForm }) => {
    dispatch(type ? registerFun(values, setType, resetForm, navigate) : loginFun(values, navigate, resetForm));
  }

  useEffect(() => {
    setType(searchParams.get('type') === 'true');
  }, [searchParams]);

  return (
    <div className='login-page w-100 h-100 pt-5 pb-5 d-flex justify-content-center align-items-center bg-primary-backgroup-color'>
      <div className='component-login container'>
        <div className='titel d-flex justify-content-center align-items-center'>
          <h3 className={`fs-5 fw-blod ${type === false && 'avtice'}`} onClick={() => setType(false)}>
            {t('login')}
          </h3>
          <h3 className={`fs-5 fw-blod ms-3 ${type === true && 'avtice'}`} onClick={() => setType(true)}>
            {t('register')}
          </h3>
        </div>
        <div className='row custorm-page-login'>
          <div className={`col-lg-6 col-12 ${type === true ? 'd-none d-lg-block' : "d-none"}`}>
            <img src={ImgBannerRegister} className='w-100 h-100' alt="Register Banner"/>
          </div>
          <div className='col-lg-6 col-12 d-flex justify-content-center align-items-center'>
            <Formik
              validationSchema={schema}
              onSubmit={postAPI}
              initialValues={{
                username: '',
                password: ''
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3 mt-3">
                    <Form.Group as={Col} md="12" className='mt-2 mb-2'>
                      <Form.Label>{t('username')}</Form.Label>
                      <Form.Control
                        className='bg-transparent'
                        placeholder={t('username_register')}
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isValid={touched.username && !errors.username}
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className='mt-2 mb-2'>
                      <Form.Label>{t('password')}</Form.Label>
                      <div className='input-password bg-transparent'>
                        <Form.Control
                          type={toggle === false ? "password" : "text"}
                          name="password"
                          className='w-100 h-100 bg-transparent'
                          placeholder={t('password_placeholder')}
                          value={values.password}
                          onChange={handleChange}
                          isValid={touched.password && !errors.password}
                        />
                        <i onClick={() => setToggle(!toggle)} className={`fa-solid ${toggle === false ? "fa-eye" : "fa-eye-slash"} icon-eye`}></i>
                      </div>
                      <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <button disabled={!isDispatch} className='custorm-btn w-100' type="submit">
                    {isDispatch ? (type ? t('register') : t('login')) : "Loading..."}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className={`col-lg-6 col-12 ${type === false ? 'd-none d-lg-block' : "d-none"}`}>
            <img src={ImgBannerLogin} className='w-100 h-100' alt="Login Banner"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
