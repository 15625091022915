import React from "react";
import ImageSession1 from "../asset/image/background-vn.webp";
import ImageSession2 from "../asset/image/background-vn (1).webp";
import "../asset/scss/component/StandbyState.scss";
function StandbyState() {
  return (
    <div className="container mt-3 standby-state-page h-100">
          
        <div className="session row h-100 p-3 p-lg-0">
          <div className="col-12 h-100 col-lg-4 d-lg-flex d-none justify-content-center align-items-center">
            <img className="w-100" src={ImageSession1}  style={{objectFit:"cover", objectPosition:"center"}} />
          </div>
          <div className="col-12 col-lg-8 d-flex justify-content-center align-items-center">
            <div>
            <h1 className=" mt-5 mb-4 color-violet fs-2 text-center">
            <i class="fa-solid fa-triangle-exclamation me-3 fs-3 "></i> Tài khoản của bạn đang được chờ xét duyệt
            </h1>
            <div className="col-12 col-lg-4 d-lg-none d-block justify-content-center align-items-center">
            <img className="w-100" src={ImageSession1} style={{objectFit:"cover", objectPosition:"center"}}  />
          </div>
              <h1 className="fs-3 fw-bold mt-4 mb-4 fs-4 fs-xxl-3 fs-xl-5">
                Bán USDT và nhận VND một cách mượt mà chỉ trong 3 bước
              </h1>
              <span className="text-uppercase fs-5 color-gary d-xxl-block d-xl-none">
                Dễ dàng nạp tiền vào ví crypto để bắt đầu giao dịch
              </span>
              <ul className="fs-6  fs-xl-7">
                <li className="d-flex align-items-center mt-4 mt-xl-2">
                  <div className="icon-standby-state d-flex justify-content-center align-items-center">
                    <p className="color-violet">1</p>
                  </div>
                  <p className="ms-3 color-violet">
                    Đăng ký và xác minh tài khoản của bạn
                  </p>
                </li>
                <li className="d-flex align-items-center mt-4 mt-xl-2">
                  <div className="icon-standby-state d-flex justify-content-center align-items-center">
                    <p className="color-violet">2</p>
                  </div>
                  <p className="ms-3 color-violet">2 Nạp USDT</p>
                </li>
                <li className="d-flex align-items-center mt-4 mt-xl-2">
                  <div className="icon-standby-state d-flex justify-content-center align-items-center">
                    <p>3</p>
                  </div>
                  <p className="ms-3 color-violet">
                    Bán USDT và rút VND về ngân hàng.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
   
    </div>
  );
}

export default StandbyState;
