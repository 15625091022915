import React ,{useEffect} from 'react'
import Chart from '../component/main/chart'
import ProjectGroup from '../component/main/projectGroup'
import Convert from '../component/main/convert'
import "../asset/scss/component/home.scss"
import {getProjectGroup} from "../service/callAPi";
import { useSelector, useDispatch } from 'react-redux';
import Coin from "../component/main/coin"
function Home() {
  const dispatch = useDispatch()
  const device = useSelector((state) => state.reducers.device);

   useEffect(()=>{
    dispatch(getProjectGroup())
   },[])
  return (
    <div className={`${device !== 'pc' ? "w-100" : "custorm-media"}  page-home h-100`}>
        <div className='row'>
            <div className='col-lg-9'>
              <Coin/>
            {/* <iframe className='w-100 iframe-home' src='https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,14,20&border=show&speed=50&click_target=blank&theme=dark&by-cr=28A745&sl-cr=DC3545&flags=circle&d_mode=regular&column=ask,bid,spread&lang=en&font=Arial,%20sans-serif'/> */}
                <Chart/>
               <div className='mt-5 d-none d-lg-block'>
               <ProjectGroup/>
               </div>
            </div>
            <div className='col-lg-3'>
                <Convert/>
            </div>
            <div className='col-12 d-block d-lg-none'>
               <ProjectGroup/>
               </div>
        </div>
    </div>
  )
}

export default Home