import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import React from 'react';
import "../../asset/scss/component/sliderBar.scss";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { setIsLogin, setModalUser, setIsClickSlideBar , setuser, setLang} from '../../redux/accction/acction';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SlideBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const device = useSelector(state => state.reducers.device);
  const isClickSlideBar = useSelector(state => state.reducers.isClickSlideBar);
  const lang = useSelector(state => state.reducers.lang);
  const logout = () => {
    Cookies.remove('token');
    dispatch(setIsLogin(false));
    dispatch(setuser(null));

    toast.success(t('logout_success'));
    setTimeout(() => {
      navigate('/');
    }, 800);
  };

  const depositWithdraw = (actionType) => {
    switch (actionType) {
      case 'DEPOSIT':
        navigate('/depositWithdraw');
        break;
      case 'WITHDRAW':
        navigate('/withdraw');
        break;
      case 'HOME':
        navigate('/home');
        break;
      default:
        navigate('/');
    }
    if (device !== 'pc') {
      dispatch(setIsClickSlideBar(false));
    }
  };
  const setlang =()=>{
    dispatch(setLang(lang ==="vi" ? "en" : 'vi'))
    dispatch(setIsClickSlideBar(false))
  }
  return (
    <Sidebar className={`d-lg-block slide-bar bg-primary-custom pb-3 pt-3 me-3 ${device !== 'pc' && (isClickSlideBar === false ? "d-none" : 'mobi')}`}>
      <div className='d-flex flex-column height-100'>
        <Menu
          className='height-100 activce'
          menuItemStyles={{
            button: {
              [`active`]: {
                backgroundColor: '#13395e',
                color: '#b6c8d9',
              },
            },
          }}
        >
          <MenuItem onClick={() => depositWithdraw('HOME')} className='d-flex d-flex justify-content-center align-items-center'>
            <i className="fa-solid fa-house"></i>
            <p className='ms-3'>{t('transactions')}</p>
          </MenuItem>
          <MenuItem onClick={() => depositWithdraw('DEPOSIT')} className='d-flex d-flex justify-content-center align-items-center'>
            <i className="fa-sharp-duotone fa-solid fa-credit-card-alt"></i>
            <p className='ms-3'>{t('usdt_wallet')}</p>
          </MenuItem>
          <MenuItem onClick={() => depositWithdraw('WITHDRAW')} className='d-flex d-flex justify-content-center align-items-center'>
            <i className="fa-sharp-duotone fa-solid fa-credit-card-alt"></i>
            <p className='ms-3'>{t('vnd_wallet')}</p>
          </MenuItem>
          <MenuItem  className='d-flex d-flex justify-content-center align-items-center'>
          <i class="fa-solid fa-language"></i>
            <p className={`lang-text ms-3`} onClick={() => dispatch(setlang)}>{t('lang')}: {lang}</p>
          </MenuItem>
          <MenuItem className='d-flex d-flex justify-content-center align-items-center d-block d-lg-none' onClick={() => dispatch(setModalUser(true))}>
            <i className="fa-solid fa-gear"></i>
            <p className='ms-3'>{t('profile')}</p>
          </MenuItem>
          <MenuItem className='d-flex justify-content-center align-items-center' onClick={logout}>
            <i className="fa-solid fa-right-from-bracket"></i>
            <p className='ms-3'>{t('logout')}</p>
          </MenuItem>
        </Menu>
      </div>
    </Sidebar>
  );
};

export default SlideBar;
