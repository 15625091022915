import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Coin from "../../component/main/coin";
import { toast } from 'react-toastify';
import { setIsLogin, setuser, setIsClickSlideBar } from '../../redux/accction/acction';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.reducers.user);
  const logout = () => {
    Cookies.remove('token');
    dispatch(setIsLogin(false));
    dispatch(setuser(null));
    toast.success(t('logout_success'));
    setTimeout(() => {
      navigate('/');
    }, 800);
  };
  return (
    <Navbar expand="lg" className="bg-body-tertiary d-block ">
    {user !== null && 
    (
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link}>
            <i class="fa-solid fa-user me-1"></i> Xin chào : {user.name}
            </Nav.Link>
            <Nav.Link as={Link} className="fw-bold ms-3" onClick={logout}>
           Đăng xuất  <i class="fa-solid fa-right-from-bracket ms-1"></i> 
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    )
    }
    
      <div>
        <Coin />
      </div>
    </Navbar>
  );
}

export default Header;
