export const setModalUser = (data) => ({
    type: 'SETMODALUSER',
    payload: data,
});
export const setModalEditUser = (data) => ({
    type: 'SETMODALEDITUSER',
    payload: data,
});
export const setModalDepositWithdraw = (data) => ({
    type: 'SETMODALDEPOSITWITHDRAW',
    payload: data,
});
export const setuser = (data) => ({
    type: 'SETUSER',
    payload: data,
});
export const setListHistory = (data) => ({
    type: 'SETLISTHISTORY',
    payload: data,
});
export const setBalanceUsdt = (data) => ({
    type: 'SETBALANCEUSDT',
    payload: data,
});
export const setBalanceVnd = (data) => ({
    type: 'SETBALANCEVND',
    payload: data,
});
export const setTransactionrate = (data) => ({
    type: 'SETTRANSACTIONRATE',
    payload: data,
});
export const setRate = (data) => ({
    type: 'SETRATE',
    payload: data,
});
export const setIsLogin = (data) => ({
    type: 'SETISLOGIN',
    payload: data,
});
export const setListProjectGroup= (data) => ({
    type: 'GETPROJECTGROUP',
    payload: data,
});
export const setDevice= (data) => ({
    type: 'SETDEVICE',
    payload: data,
});
export const setIsClickSlideBar= (data) => ({
    type: 'SETISCLICKSLIDEBAE',
    payload: data,
});
export const setModalUpdatePassword= (data) => ({
    type: 'SETMODALUPDATEPASSWORD',
    payload: data,
});
export const setAllVND= (data) => ({
    type: 'SETALLVND',
    payload: data,
});
export const setAllUSDT= (data) => ({
    type: 'SETALLUSDT',
    payload: data,
});
export const setwithdrawVND= (data) => ({
    type: 'SETWITHDRAWVND',
    payload: data,
});
export const setIsDispatch= (data) => ({
    type: 'SETISDISPATCH',
    payload: data,
});
export const setTransactionUSDT= (data) => ({
    type: 'SETTRANSACTIONUSDT',
    payload: data,
});
export const setListHistoryLoadmore= (data) => ({
    type: 'SETLISTHISTORYLOADRMORE',
    payload: data,
});
export const setPage= (data) => ({
    type: 'SETPAGE',
    payload: data,
});

export const setNotification_banned_vnd= (data) => ({
    type: 'SETNOTIFICATION_BANNED_VND',
    payload: data,
});
export const setNotification_identity= (data) => ({
    type: 'SETNOTIFICATION_IDENTITY',
    payload: data,
});
export const setVerify= (data) => ({
    type: 'SETVERIFY',
    payload: data,
});
export const setModelVerify= (data) => ({
    type: 'SETMODALVERIFY',
    payload: data,
});
export const setLang= (data) => ({
    type: 'SETLANG',
    payload: data,
});