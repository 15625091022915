import React from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

const Chart = () => {
  return (
    <div className="chart-container" style={{ height: '300px', width: '100%' }}>
      <TradingViewWidget
        symbol="CRYPTOCAP:USDT"
        theme={Themes.DARK}  
        locale="en"     
        autosize  
      />
    </div>
  );
};

export default Chart;
