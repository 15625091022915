import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Index from '../page/index';
import Login from '../page/login';
import StandbyState from '../page/standbyState';
import Home from '../page/home';
import DepositWithdraw from '../page/depositWithdraw';
import ProtectedRoute  from "../middlawe/ProtectedRoute"
import Withdraw from '../page/withdraw';
function Main() {
  return (
    <Routes>
        <Route path="/home"  element={<ProtectedRoute element={<Home />} />} />
        <Route path="/depositWithdraw"  element={<ProtectedRoute element={<DepositWithdraw />} />} />
        <Route path="/withdraw"  element={<ProtectedRoute element={<Withdraw />} />} />
        <Route path="/standbyState"  element={<ProtectedRoute element={<StandbyState />} />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/login" element={<ProtectedRoute element={<Login />} redirectPath="/home" />} />

    </Routes>
  )
}

export default Main;
