import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot từ react-dom/client
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import '@patternfly/react-core/dist/styles/base.css';

// Tạo root bằng createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render ứng dụng với createRoot
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
