import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import store from '../redux/store';

const APILink = axios.create({
  baseURL: process.env.REACT_APP_SERVER_LINK,
});

// Set up interceptors
APILink.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    const state = store.getState();
    const language = state.reducers.lang || "vi"; 

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    config.headers['Accept-Language'] = language; 

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

APILink.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        toast.error('Vui lòng đăng nhập lại');
        Cookies.remove('token');
      } else if (status === 403) {
        // window.location.href = '/standbyState'; 
      } else if (status === 422) {
        if (data.errors) {
          Object.keys(data.errors).forEach((key) => {
            const errorMessages = data.errors[key];
            if (errorMessages.length > 0) {
              toast.error(`${key}: ${errorMessages[0]}`);
            }
          });
        } else if (data.message) {
          toast.error(data.message);
        }
      }
    } 

    return Promise.reject(error);
  }
);

export default APILink;
