import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element, redirectPath }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const token = Cookies.get('token');
  const [tokenFromUrl, setTokenFromUrl] = useState(null);
  const user = useSelector((state) => state.reducers.user);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenUrl = urlParams.get('token');

    if (tokenUrl) {
      Cookies.set('token', tokenUrl, { expires: 30 });
      setTokenFromUrl(tokenUrl);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }


  if (token || tokenFromUrl) {
    if (location.pathname === '/login') {
      return <Navigate to={redirectPath || '/user'} />;
    }
     else if (location.pathname === '/standbyState') {
      if (user !== null && user.accept_identity === 2) {
        return <Navigate to="/home" />;

      }
      return element;
    } else {
      if (user !== null && user.accept_identity !== 2) {
        return <Navigate to="/standbyState" />;
      }
      return element;
    }

  }
  else if (!token && location.pathname !== '/login') {
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;
