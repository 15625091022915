import React, { useState, useEffect,useRef } from 'react';
import './asset/scss/index.scss';
import Router from "./router/router";
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./component/header/header";
import HeaderHome from "./component/header/headerHome";
import SlideBar from './component/slideBar/slideBar';
import { useLocation } from 'react-router-dom';
import ModalUser from './component/modal/user';
import ModalVerify from './component/modal/verify';
import Edituser from './component/modal/edituser';
import { useSelector, useDispatch } from 'react-redux';
import { getUserInfro, setting } from "./service/callAPi";
import { callRate } from "./service/connectAPI";
import UpdatePassWord from "./component/modal/updatePassword"
import {getDeviceType} from "./service/funweb"
import {setIsClickSlideBar} from "./redux/accction/acction";
import ChaportChatbot from './component/chat/chatbot';
import Footer from './component/footer/footer';
import i18n from './lang/lang';
import Cookies from 'js-cookie';
function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const modalUser = useSelector(state => state.reducers.modalUser);
  const modalEditUser = useSelector(state => state.reducers.modalEditUser);
  const  isLogin = useSelector(state => state.reducers.isLogin);
  const modalUpdatePassword = useSelector((state) => state.reducers.modalUpdatePassword);
  const modalVerify = useSelector((state) => state.reducers.modalVerify);
  const lang = useSelector((state) => state.reducers.lang);
  const device = useSelector((state) => state.reducers.device);
  const token = Cookies.get('token');;
  useEffect(() => {
      if (isLogin || token  ) {
        dispatch(getUserInfro());
      }
  }, [isLogin, token]);
  useEffect(() => {
    dispatch(getDeviceType(window.innerWidth))
  }, []);

  useEffect(() => {
      if(isLogin === true){
        dispatch(setting());
        const checkTimeAndCallAPI = () => {
          const now = new Date();
          const hours = now.getHours();
          const minutes = now.getMinutes();
          const seconds = now.getSeconds();
            const targetDate = new Date();
          targetDate.setHours(6, 5, 0, 0); 
          if (hours === 6 && minutes === 5 && seconds === 0) {
            dispatch(setting());
            return;
          }
          const timeToTarget = targetDate - now;
          const delay = timeToTarget < 0 ? (24 * 60 * 60 * 1000) + timeToTarget : timeToTarget;
            setTimeout(() => {
            dispatch(setting());
          }, delay);
        };
        checkTimeAndCallAPI();
    };
  }, [isLogin]);
  useEffect(() => {
    if(device !== "pc"){
      function handleClickOutside(event) {
        if (!event.target.closest('.ps-sidebar-root') && !event.target.closest('header') ) {
          dispatch(setIsClickSlideBar(false))
        }
      }
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, []);
  React.useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <div className={`warper ${currentPath === '/login' &&  "max-h-100"}`}>
      <div className="container-fluid p-0">
        <ToastContainer />
        {/* <ChaportChatbot/> */}
        {modalUser === true && <ModalUser />}
        {modalEditUser === true && <Edituser />}
        {modalVerify === true  && <ModalVerify/> }
        {modalUpdatePassword === true && <UpdatePassWord/> }
        {(isLogin === true || (token && token !== null)) && currentPath !== '/standbyState' ?  <HeaderHome />: <Header />}
        <main className={`w-100 d-flex ${isLogin === true  && currentPath !== '/standbyState'? 'bg-black min-h-100' : `p-0 ${device  === 'pc' && "h-auto-custorm"} `}`}>
          {(isLogin === true || (token && token !== null))  && currentPath !== '/standbyState' && <SlideBar />}
          <Router />
        </main>
        {currentPath === '/standbyState' && <Footer />}
      </div>
    </div>
  );
}

export default App;
