import Table from "react-bootstrap/Table";
import { getHistoty, loadMoreFun } from "../../service/callAPi";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { formatDate1 } from "../../service/funweb";
import { setPage } from "../../redux/accction/acction";
function DepositWithdraw({ type }) {
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [limit, setLimit] = useState(10);
  const listHistory = useSelector((state) => state.reducers.listHistory);
  const isDispatch = useSelector((state) => state.reducers.isDispatch);
  const page = useSelector((state) => state.reducers.page);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDispatch === true) {
      dispatch(
        getHistoty(
          type,
          page,
          limit,
          isLoadmore,
          setIsLoadmore,
          setPage,
          isDispatch
        )
      );
    }
  }, [type, page]);
  const loadmore = () => {
    if (isDispatch === true) {
      dispatch(setPage(page + 1));
      setIsLoadmore(true);
    }
  };

  return (
    <>
      <Table striped bordered hover className="table-responsive">
        <thead>
          <tr>
            <th>Thời gian</th>
            <th>{type !== "convert" ? "Số tiền" : "Số USDT"}</th>
            <th>Loại</th>
            <th>{type === "convert" ? "Loại hình" : "Trạng thái"}</th>
            {type === "convert" && <th>Số tiền VND</th>}
            <th>Nội dung</th>
          </tr>
        </thead>
        <tbody>
          {listHistory.length > 0 ? (
            listHistory.map((item, index) => (
              <tr key={index}>
                <td>{formatDate1(item.created_at)}</td>
                <td>{type !== "convert" ? item.amount : item.amount_usd}</td>
                <td>
                  {(() => {
                    switch (type) {
                      case "convert":
                        return "Bán USDT";
                      case "recharge":
                        return "Nạp USDT";
                      default:
                        return "Rút VND";
                    }
                  })()}
                </td>
                <td>
                  {" "}
                  {type !== "convert"
                    ? (() => {
                        switch (item.is_approve) {
                          case 0:
                            return "Đang chờ duyệt";
                          case 1:
                            return "Đã duyệt";
                          default:
                            return "Không được duyệt";
                        }
                      })()
                    : item.is_group === 0
                    ? "Bán tự động"
                    : "Bán kết hợp"}
                </td>

                {type === "convert" && (
                  <td>
                    {item.amount_vnd && item.amount_vnd !== null
                      ? item.amount_vnd
                      : "Không có dữ liệu"}
                  </td>
                )}
                <td>
                  {item.description && item.description !== null
                    ? item.description
                    : "Không có nội dung"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center justify-content-center" colSpan={type === "recharge" ? 5 : 6}>Không có dữ liệu</td>
            </tr>
          )}
        </tbody>
      </Table>
      {isLoadmore && (
        <div className="text-center mt-5 d-flex justify-content-center align-items-center">
          <div className="btn-loadmore" onClick={loadmore}>
            {isDispatch === true ? "xem thêm" : "Loading.."}{" "}
          </div>
        </div>
      )}
    </>
  );
}

export default DepositWithdraw;
