import React, { useState } from 'react';
import DepositWithdrawModal from "../component/modal/depositWithdraw";
import DepositWithdrawList from "../component/main/depositWithdraw";
import Up from "../asset/image/download (1).png";
import "../asset/scss/component/depositWithdraw.scss";
import { useSelector, useDispatch } from 'react-redux';
import { setModalDepositWithdraw, setPage, setListHistory } from "../redux/accction/acction";
import { formatCurrency } from "../service/funweb";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function DepositWithdraw() {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [type, setType] = useState(false);
    const user = useSelector(state => state.reducers.user);
    const balanceUsdt = useSelector(state => state.reducers.balanceUsdt);
    const formattedbalanceUsdt = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(balanceUsdt);
    const allUsdt = useSelector(state => state.reducers.allUsdt);
    const transactionUSDT = useSelector(state => state.reducers.transactionUSDT);
    const [typeHistory, setTypeHistory] = useState('recharge');

    const recharge = () => {
        dispatch(setModalDepositWithdraw(true));
        setType(false);
    };

    const clickRecharge = () => {
        dispatch(setListHistory([]));
        dispatch(setPage(1));
        setTypeHistory('recharge');
    };

    const clickConvert = () => {
        dispatch(setListHistory([]));
        dispatch(setPage(1));
        setTypeHistory('convert');
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(user !== null && user.wallet_number !== null ? user.wallet_number : "").then(() => {
            toast.success('Wallet address copied');
        }).catch(err => {
            console.error('Error copying text: ', err);
        });
    };

    return (
        <div className='depositWithdraw-page w-100 p-5'>
            <div className='box-header p-4 mb-5'>
            <h1 className='fs-5 fw-bold mb-3'>{t('totalUSDT')}</h1>
                <p>$ {formattedbalanceUsdt}</p>
            </div>
            <div>
                <h4>{t('mainAccount')}</h4>
                <div>
                    <div className='d-block d-lg-flex justify-content-between align-items-center'>
                        <div className='d-flex d-flex align-items-center '>
                            <svg width="44px" height="44px" viewBox="0 0 1024 1024" className="icon d-none d-lg-block" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path d="M617.1 671.2H148.2c-46.3 0-83.9-37.6-83.9-83.9v-307c0-46.3 37.6-83.9 83.9-83.9h468.9c46.3 0 83.9 37.6 83.9 83.9v306.9c0 46.4-37.5 84-83.9 84z" fill="#248CBE"></path>
                                    <path d="M64.3 302.3h636.8v96.1H64.3z" fill="#183351"></path>
                                    <path d="M304 531.8H128.3c-12.1 0-22-9.9-22-22s9.9-22 22-22H304c12.1 0 22 9.9 22 22s-9.9 22-22 22zM437.4 601.2H128.3c-12.1 0-22-9.9-22-22s9.9-22 22-22h309.1c12.1 0 22 9.9 22 22s-9.9 22-22 22z" fill="#FBFAEE"></path>
                                    <path d="M875.8 827.6H406.9c-46.3 0-83.9-37.6-83.9-83.9v-307c0-46.3 37.6-83.9 83.9-83.9h468.9c46.3 0 83.9 37.6 83.9 83.9v306.9c0 46.4-37.5 84-83.9 84z" fill="#F5DB6F"></path>
                                    <path d="M788.3 607.2H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h401.3c12.1 0 22 9.9 22 22s-9.9 22-22 22z" fill="#AEB8C4"></path>
                                    <path d="M562.7 688.2H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h175.8c12.1 0 22 9.9 22 22-0.1 12.1-10 22-22.1 22zM696.1 757.6H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h309.1c12.1 0 22 9.9 22 22s-9.9 22-22 22z" fill="#FBFAEE"></path>
                                    <path d="M510.4 520.3H401.8c-20.3 0-36.8-16.5-36.8-36.8v-50.8c0-20.3 16.5-36.8 36.8-36.8h108.6c20.3 0 36.8 16.5 36.8 36.8v50.8c0 20.3-16.5 36.8-36.8 36.8z" fill="#F3C262"></path>
                                    <path d="M547.2 438.1h-34.3v-42.2H479v124.4h33.9v-47.7h34.3zM402.6 395.9v42.2H365v34.5h37.6v47.7h33.9V395.9z" fill="#F7A04D"></path>
                                    <path d="M959.7 577.8V484c-80.6-0.3-148.2-56.4-166.1-131.6h-95.5c19.2 127.2 129.2 225.1 261.6 225.4z" fill="#F7F9DD"></path>
                                </g>
                            </svg>
                            <div className='ms-0 ms-lg-3'>
                                <p>{t('usdtBalance')}: {formattedbalanceUsdt}</p>
                                <div className='d-flex'>
                                    <small>{t('walletAddress')}: {user !== null && user.wallet_number !== null ? user.wallet_number : t('notUpdated')}</small>
                                    <div className='d-lg-flex justify-content-between align-items-center' onClick={handleCopy}>
                                        <i className="fa-solid fa-copy ms-3 fs-5"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className='text-ld-end'>{t('usdtDeposited')}: {formatCurrency(transactionUSDT)} $</p>
                            <p>{t('usdtSold')}: {formatCurrency(allUsdt)} $</p>
                        </div>
                    </div>
                    <hr />
                    <div className='d-flex justify-content-between align-items-center'>
                        <div onClick={recharge}>
                            <img src={Up} alt={t('depositUSDT')} />
                            <p>{t('depositUSDT')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <DepositWithdrawModal type={type} />
            <div className="w-100 mt-5">
                <div className="titel d-flex mb-4">
                    <h3 className={`fs-5 fw-blod ${typeHistory === "recharge" && 'avtice'}`} onClick={clickRecharge}>
                        {t('depositUSDT')}
                    </h3>
                    <h3 className={`fs-5 fw-blod ms-3 ${typeHistory === 'convert' && 'avtice'}`} onClick={clickConvert}>
                        {t('sellUSDT')}
                    </h3>
                </div>
                <DepositWithdrawList type={typeHistory} />
            </div>
        </div>
    );
}

export default DepositWithdraw;