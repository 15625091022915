import React from "react";
import ImageSession2 from "../../asset/image/background-vn (1).webp";
import "../../asset/scss/component/footer.scss";
import { useSelector, useDispatch } from "react-redux";

function Footer() {
  const device = useSelector((state) => state.reducers.device);
  console.log();
  return (
    <footer
      className={`session row bg-pink ${device === "pc" && "h-footer-200"}`}
    >
      <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center p-5 p-lg-0">
        <div>
          <h2>Bảo mật & An toàn</h2>
          <span>
            TOP 15 sàn giao dịch an toàn nhất bởi CER.live. Sự lựa chọn đáng tin
            cậy của bạn!
          </span>
          {/* <ul>
            <li className="fs-6">
              <i class="fa-brands fa-telegram me-2"></i> telegram :{" "}
              <a>vũ linh hồ 3213213</a>
            </li>
            <li className="fs-6">
              <i class="fa-brands fa-facebook me-2"></i> facebook :{" "}
              <a>vũ linh hồ 3213213</a>
            </li>
          </ul> */}
        </div>
      </div>
      <div className="col-12 col-lg-6 h-100 d-none d-lg-flex justify-content-center align-items-center">
        <img className="h-100 h-100" src={ImageSession2} />
      </div>
    </footer>
  );
}

export default Footer;
