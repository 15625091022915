import React, { useState } from 'react';
import DepositWithdrawModal from "../component/modal/depositWithdraw";
import DepositWithdrawList from "../component/main/depositWithdraw";
import Down from "../asset/image/download.png";
import "../asset/scss/component/depositWithdraw.scss";
import { useSelector, useDispatch } from 'react-redux';
import { setModalDepositWithdraw } from "../redux/accction/acction";
import { formatCurrency } from "../service/funweb";
import { useTranslation } from 'react-i18next';

function DepositWithdraw() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [type, setType] = useState(false);
    const user = useSelector(state => state.reducers.user);
    const balanceVnd = useSelector(state => state.reducers.balanceVnd);
    const formattedbalanceVnd = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(balanceVnd);
    const allVnd = useSelector(state => state.reducers.allVnd);
    const formattedAllVnd = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(allVnd);
    const withdrawVND = useSelector(state => state.reducers.withdrawVND);
    const formattedWithdrawVND = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(withdrawVND);
    const [typeHistory, setTypeHistory] = useState('withdraw');

    const withdraw = () => {
        dispatch(setModalDepositWithdraw(true));
        setType(true);
    };

    return (
        <div className='depositWithdraw-page w-100 p-5'>
            <div className='box-header p-4 mb-5'>
                <h1 className='fs-5 fw-bold mb-3'>{t('totalVND')}</h1>
                <p>{formattedbalanceVnd} VND</p>
            </div>
            <div>
                <h4>{t('mainAccount')}</h4>
                <div>
                    <div className='d-block d-lg-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <svg width="44px" height="44px" viewBox="0 0 1024 1024" className="icon d-none d-lg-block" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                {/* SVG Content */}
                            </svg>
                            <div className='ms-0 ms-lg-3'>
                                <p>{t('walletBalance')}: {formattedbalanceVnd} VND</p>
                                <small>{t('bankName')}: {user !== null && user.bank_name !== null ? user.bank_name : t('notUpdated')}</small><br/>
                                <small>{t('accountNumber')}: {user !== null && user.bank_number !== null ? user.bank_number : t('notUpdated')}</small>
                            </div>
                        </div>
                        <div className=''>
                            <p className='text-lg-end'>{t('amountWithdrawn')}: {formattedWithdrawVND} VND</p>
                            <p>{t('amountConverted')}: {formattedAllVnd} VND</p>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className='d-flex justify-content-between align-items-center'>
                    <div onClick={withdraw}>
                        <img src={Down} alt={t('withdrawMoney')} />
                        <p>{t('withdrawMoney')}</p>
                    </div>
                </div>
            </div>
            <DepositWithdrawModal type={type} />
            <div className="w-100 mt-5">
                <div className="titel d-flex mb-4">
                    <h3 className={`fs-5 fw-blod ms-3 ${typeHistory === 'withdraw' && 'avtice'}`} onClick={() => setTypeHistory('withdraw')}>
                        {t('withdrawMoney')}
                    </h3>
                </div>
                <DepositWithdrawList type={typeHistory} />
            </div>
        </div>
    );
}

export default DepositWithdraw;
