import APILink from "../connect/APILink";

import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import {
  setModalEditUser, setuser, setListHistory,
  setBalanceUsdt, setBalanceVnd, setTransactionrate,
  setRate, setIsLogin, setListProjectGroup, setModalUpdatePassword,
  setwithdrawVND , setTransactionUSDT, setAllUSDT, setAllVND,setVerify,
  setModalUser, setIsDispatch ,setListHistoryLoadmore, setNotification_banned_vnd , setNotification_identity
} from "../redux/accction/acction";

export const loginFun = (value, navigate, resetForm) => {
  return (dispatch) => {
     dispatch(setIsDispatch(false));
    APILink.post('login', value)
      .then((response) => {
        if (response.data.status === "success") {
          Cookies.set('token', response.data.token, { expires: 30, secure: true, sameSite: 'Strict' });
          dispatch(setIsLogin(true));
          toast.success(response.data.message);
          navigate('/home')
          resetForm();
          dispatch(getUserInfro())
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsDispatch(true));
      });
  }
}
export const registerFun = (value, setType, resetForm, navigate) => {
  return (dispatch) => {
       dispatch(setIsDispatch(true));
    APILink.post('register', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          resetForm();
          setType(false)
          navigate('/standbyState');
          Cookies.set('token', response.data.token, { expires: 30, secure: true, sameSite: 'Strict' });
          resetForm();
          dispatch(getUserInfro())
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsDispatch(true));
      });
  }
}

export const veryficationFun = (value, resetForm) => {
  return (dispatch) => {
    ///   dispatch(setLoadingAcction(true));
    APILink.post('veryfication-user', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          resetForm();
          dispatch(setModalEditUser(false));
         /// dispatch(setModalUser(false))
          dispatch(getUserInfro())
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
    //   .finally(() => {
    //     dispatch(setLoadingAcction(false));
    //   });
  }
}


export const getUserInfro = () => {
  return (dispatch) => {
    dispatch(setBalanceUsdt("Loading..."));
    dispatch(setBalanceVnd("Loading..."));
    APILink.get('user')
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(setuser(response.data.user));
          dispatch(setBalanceUsdt(response.data.balanceUsdt));
          dispatch(setBalanceVnd(response.data.balanceVnd));
          dispatch(setAllVND(response.data.allvnd));
          dispatch(setAllUSDT(response.data.allUsdt));
          dispatch(setwithdrawVND(response.data.withdrawVND));
          dispatch(setTransactionUSDT(response.data.transactionUSDT));
        } else {
          toast.error(response.data.message);
          dispatch(setBalanceUsdt(0));
          dispatch(setBalanceVnd(0));
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }
}
export const setting = () => {
  return (dispatch) => {
    APILink.get('setting')
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(setTransactionrate(response.data.transactionrate));
          dispatch(setRate(response.data.rate));
          dispatch(setNotification_banned_vnd(response.data.notification_banned_vnd));
          dispatch(setNotification_identity(response.data.notification_identity));
          // dispatch(setVerify(response.data.verify));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }
}

export const getHistoty = (type, page, limit,isLoadmore =false , setIsLoadmore = null, setPage = null , isDispatch =true) => {
  return (dispatch) => {
    if (isDispatch === false) return;
   dispatch(setIsDispatch(false));
    APILink.get(`history?type=${type}&page=${page}&limit=${limit}`)
      .then((response) => {
        if (response.data.status === "success") {
          isLoadmore === false ? 
          dispatch(setListHistory(response.data.result.data || response.data.result)) :
          dispatch(setListHistoryLoadmore(response.data.result.data || response.data.result))
          setIsLoadmore !==null &&   setIsLoadmore( response.data.result.next_page_url !== null ? true : false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      }).finally(()=>{
        dispatch(setIsDispatch(true))
      })
  }
}
export const loadMoreFun = (type, page, limit) => {
  return (dispatch) => {
    APILink.get(`history?type=${type}&?page=${page}&?limit=${limit}`)
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(setListHistory(response.data.result.data || response.data.result))
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }
}
export const rechargeFun = (value, resetForm) => {
  return (dispatch) => {
      dispatch(setIsDispatch(false));
    APILink.post('recharge', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          resetForm();
          dispatch(setModalEditUser(false))
          dispatch(getHistoty('recharge', 1, 10))
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsDispatch(true));
      });
  }

}
export const withdrawFun = (value, resetForm) => {
  return (dispatch) => {
    ///   dispatch(setLoadingAcction(true));
    APILink.post('withdraw', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          resetForm();
          dispatch(setModalEditUser(false));
          dispatch(getHistoty('withdraw', 1, 10))

        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
    //   .finally(() => {
    //     dispatch(setLoadingAcction(false));
    //   });
  }

}
export const convertFun = (value, setAmount) => {
  
  return (dispatch) => {
    ///   dispatch(setLoadingAcction(true));
    APILink.post('convert', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          setAmount(0);
          dispatch(getHistoty('convert', 1, 10))
          dispatch(getUserInfro())
          // dispatch(setBalance(value.amount_usd))
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
    //   .finally(() => {
    //     dispatch(setLoadingAcction(false));
    //   });
  }
}
export const updatePasswordFun = (value) => {
  return (dispatch) => {
       dispatch(setIsDispatch(false));
    APILink.post('update-password', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          dispatch(setModalUpdatePassword(false));
          dispatch(getUserInfro())
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsDispatch(true));
      });
  }
}
export const updateImageFun = (value) => {
  return (dispatch) => {
    ///   dispatch(setLoadingAcction(true));
    APILink.post('update-img', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
    //   .finally(() => {
    //     dispatch(setLoadingAcction(false));
    //   });
  }
}
export const verifyFun = (value) => {
  return (dispatch) => {
    dispatch(setIsDispatch(false));
    APILink.post('verify', value)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsDispatch(true));
      });
  }
}
export const getProjectGroup = () => {
  return (dispatch) => {
    APILink.get(`get-group`)
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(setListProjectGroup(response.data.result.data || response.data.result))
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }
}


export const setBalance = (amount) => {
  return (dispatch, getState) => {
    const state = getState();
    const rate = state.reducers.rate;
    const transactionrate = state.reducers.transactionrate;
    const balanceUsdt = state.reducers.balanceUsdt;
    const balanceVnd = state.reducers.transactibalanceVndonrate;
    dispatch(setBalanceUsdt(balanceUsdt - amount));
    dispatch(setBalanceVnd(balanceVnd + (amount *rate *((transactionrate + 100) /100) )))
  };
};
