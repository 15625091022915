import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { setModelVerify } from '../../redux/accction/acction';
import { verifyFun } from '../../service/callAPi';
import { useTranslation } from 'react-i18next';

function Example() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.reducers.user);
    const isDispatch = useSelector(state => state.reducers.isDispatch);
    const show = useSelector(state => state.reducers.modalVerify);

    const handleClose = () => {
        dispatch(setModelVerify(false));
    };

    const initialValues = {
        amount: '',
        image: null,
        back_image: null,
        front_image: null,
    };

    const validationSchema = yup.object().shape({
        amount: yup.number()
            .required(t('amount_required'))
            .positive(t('amount_positive')),
        image: yup.mixed().required(t('usdt_image_required')),
        back_image: yup.mixed().required(t('id_back_image_required')),
        front_image: yup.mixed().required(t('id_front_image_required')),
    });

    const handleSubmitForm = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.append('amount', values.amount);
        formData.append('image', values.image);
        formData.append('back_image', values.back_image);
        formData.append('front_image', values.front_image);

        try {
            await dispatch(verifyFun(formData));
            handleClose();
            resetForm();
        } catch (error) {
            console.error('Error uploading:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal-custorm">
            <Modal.Header closeButton>
                <Modal.Title>{t('verification')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmitForm}
                >
                    {({ handleSubmit, setFieldValue, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Form.Label>{t('wallet_id')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={user?.wallet_number}
                                        disabled
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Form.Label>{t('amount')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        isInvalid={touched.amount && !!errors.amount}
                                        onChange={(e) => setFieldValue('amount', e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.amount}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Form.Label>{t('usdt_image')}</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="image"
                                        onChange={(event) =>
                                            setFieldValue('image', event.currentTarget.files[0])
                                        }
                                        isInvalid={touched.image && !!errors.image}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.image}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Form.Label>{t('id_front_image')}</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="front_image"
                                        onChange={(event) =>
                                            setFieldValue('front_image', event.currentTarget.files[0])
                                        }
                                        isInvalid={touched.front_image && !!errors.front_image}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.front_image}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mt-4">
                                    <Form.Label>{t('id_back_image')}</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="back_image"
                                        onChange={(event) =>
                                            setFieldValue('back_image', event.currentTarget.files[0])
                                        }
                                        isInvalid={touched.back_image && !!errors.back_image}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.back_image}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Button type="submit" disabled={!isDispatch}>
                                {isDispatch ? t('verify') : t('loading')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default Example;
