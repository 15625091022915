import Cookies from 'js-cookie';
const initialState = {
  isLogin: Cookies.get('token') && Cookies.get('token') !== null ? true : false,
  modalUser: false,
  lang: "vi",
  modalEditUser: false,
  modalUpdatePassword: false,
  modalDepositWithdraw: false,
  modalVerify: false,
  user: null,
  listHistory: [],
  balanceUsdt: 0,
  balanceVnd: 0,
  allUsdt: 0,
  allVnd: 0,
  transactionUSDT: 0,
  withdrawVND: 0,
  rate: 0,
  transactionrate: 0,
  listProjectGroup: [],
  device: 'pc',
  isClickSlideBar: false,
  isDispatch: true,
  page: 1,
  notification_banned_vnd: null,
  notification_identity: null,
  verify: null
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETISLOGIN':
      return {
        ...state,
        isLogin: action.payload,
      };
    case 'SETMODALUSER':
      return {
        ...state,
        modalUser: action.payload,
      };
    case 'SETMODALEDITUSER':
      return {
        ...state,
        modalEditUser: action.payload,
      };
    case 'SETMODALDEPOSITWITHDRAW':
      return {
        ...state,
        modalDepositWithdraw: action.payload,
      };
    case 'SETUSER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SETLISTHISTORYLOADRMORE':
      return {
        ...state,
        listHistory: [...state.listHistory, ...action.payload],
      };
    case 'SETLISTHISTORY':
      return {
        ...state,
        listHistory: action.payload,
      };
    case 'SETBALANCEUSDT':
      return {
        ...state,
        balanceUsdt: action.payload,
      };
    case 'SETBALANCEVND':
      return {
        ...state,
        balanceVnd: action.payload,
      };
    case 'SETTRANSACTIONRATE':
      return {
        ...state,
        transactionrate: action.payload,
      };
    case 'SETRATE':
      return {
        ...state,
        rate: action.payload,
      };
    case 'GETPROJECTGROUP':
      return {
        ...state,
        listProjectGroup: action.payload,
      };
    case 'SETDEVICE':
      return {
        ...state,
        device: action.payload,
      };
    case 'SETISCLICKSLIDEBAE':
      return {
        ...state,
        isClickSlideBar: action.payload,
      };
    case 'SETMODALUPDATEPASSWORD':
      return {
        ...state,
        modalUpdatePassword: action.payload,
      };
    case 'SETALLUSDT':
      return {
        ...state,
        allUsdt: action.payload,
      };
    case 'SETALLVND':
      return {
        ...state,
        allVnd: action.payload,
      };
    case 'SETWITHDRAWVND':
      return {
        ...state,
        withdrawVND: action.payload,
      };
    case 'SETTRANSACTIONUSDT':
      return {
        ...state,
        transactionUSDT: action.payload,
      };
    case 'SETISDISPATCH':
      return {
        ...state,
        isDispatch: action.payload,
      };
    case 'SETPAGE':
      return {
        ...state,
        page: action.payload,
      };
    case 'SETNOTIFICATION_BANNED_VND':
      return {
        ...state,
        notification_banned_vnd: action.payload,
      };
    case 'SETNOTIFICATION_IDENTITY':
      return {
        ...state,
        notification_identity: action.payload,
      };
    case 'SETVERIFY':
      return {
        ...state,
        verify: action.payload,
      };
    case 'SETMODALVERIFY':
      return {
        ...state,
        modalVerify: action.payload,
      };
      case 'SETLANG':
        return {
          ...state,
          lang: action.payload,
        };
  
    default:
      return state;
  }
};

export default Reducer;