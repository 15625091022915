import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useSelector, useDispatch } from 'react-redux';
import { setModalUpdatePassword } from "../../redux/accction/acction";
import { updatePasswordFun } from "../../service/callAPi";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Example() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.reducers.modalUpdatePassword);
  const isDispatch = useSelector((state) => state.reducers.isDispatch);

  const handleClose = () => dispatch(setModalUpdatePassword(false));
  const [validated, setValidated] = useState(false);
  const [passwordOld, setPasswordOld] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [apasswordNew, setAPasswordNew] = useState('');

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const changePasswordFun = () => {
    if (apasswordNew === passwordNew) {
      const data = {
        passwordOld: passwordOld,
        passwordNew: passwordNew
      };
      dispatch(updatePasswordFun(data));
    } else {
      toast.error(t('password_mismatch'));
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className='update-password modal-custorm'>
        <div className='w-100 h-100 position-relative'>
          <div className='icon-close-update-password flex-class justify-content-center' onClick={() => dispatch(setModalUpdatePassword(false))}>
            <i className="fa-solid fa-xmark "></i>
          </div>
          <Modal.Header className='pt-4 pb-4'>
            <Modal.Title>{t('change_password')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                  <Form.Label>{t('old_password')}</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    className='input-update-password'
                    onChange={(e) => setPasswordOld(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                  <Form.Label>{t('new_password')}</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    className='input-update-password'
                    onChange={(e) => setPasswordNew(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" className='mt-3 mp-3'>
                  <Form.Label>{t('confirm_password')}</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    className='input-update-password'
                    onChange={(e) => setAPasswordNew(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!isDispatch} variant="primary" className='btn-update-password bg-transparent' onClick={changePasswordFun}>
              {isDispatch === false ? t('processing') : t('update_password')}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default Example;
