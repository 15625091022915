import {setDevice} from "../redux/accction/acction";
import moment from "moment";
export function formatCurrency(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
export function getDeviceType(width) { 
    return (dispatch) => {
        if (width < 768) {
            dispatch(setDevice('mobile'));
          } else if (width < 992) {
            dispatch(setDevice('tablet')); 
          } else {
            dispatch(setDevice('pc'));
          }
    } 

  }
  export const formatDate1 = (isoDate) => {
    return moment(isoDate).format('HH:mm:ss DD-MM-YYYY');
  };
  export const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND'
      }).format(price);
};
export  const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};
export const formatDate = (isoDate) => {
  return moment(isoDate).format('YYYY-MM-DD HH:mm:ss');
};