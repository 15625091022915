import React from 'react';
import { Formik } from 'formik';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector, useDispatch } from 'react-redux';
import { setModalDepositWithdraw } from "../../redux/accction/acction";
import { rechargeFun, withdrawFun } from "../../service/callAPi";
import { useTranslation } from 'react-i18next';

function Example({ type }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.reducers.user);
    const isDispatch = useSelector((state) => state.reducers.isDispatch);
    const balanceVnd = useSelector((state) => state.reducers.balanceVnd);
    const show = useSelector((state) => state.reducers.modalDepositWithdraw);

    const initialValues = {
        amount: '',
        image: null,
        bank_name: user?.bank_name || '',
        bank_number: user?.bank_number || '',
        fullname: user?.full_name || '',
        wallet_number: user?.wallet_number || '',
        type: type, // Ensure type is in initialValues if used in schema
    };

    const handleClose = () => dispatch(setModalDepositWithdraw(false));

    const handleSubmitForm = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.append('amount', values.amount);
        if (values.image && type === false) {
            formData.append('image', values.image);
        }

        try {
            if (type === false) {
                dispatch(rechargeFun(formData, resetForm));
            } else {
                dispatch(withdrawFun(formData, resetForm));
            }
            handleClose();
        } catch (error) {
            console.error('Error uploading:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal-custorm">
            <Modal.Header closeButton>
                <Modal.Title>{type === false ? t('rechargeUSDT') : t('withdrawVND')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={handleSubmitForm}
                    initialValues={initialValues}
                >
                    {({ handleSubmit, handleChange, setFieldValue, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationFormikAmount">
                                    <Form.Label>{t('amount')}</Form.Label>
                                    <div className='form-input-amount w-100'>
                                        <input
                                        className='w-100 h-100'
                                            type="text"
                                            name="amount"
                                            value={values.amount}
                                            onChange={handleChange}
                                        />
                                         {type === true && (
                                    <div
                                        className='set-all-amount'
                                        onClick={() => setFieldValue('amount', balanceVnd)}
                                    >
                                        {t('withdrawAll')}
                                    </div>
                                )}
                                    </div>
                                   
                                   
                                </Form.Group>

                                {type !== true && (
                                    <Form.Group as={Col} md="12" controlId="validationFormikImage">
                                        <Form.Label>{t('image')}</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={(event) => {
                                                setFieldValue('image', event.currentTarget.files[0]);
                                            }}
                                        />
                                    </Form.Group>
                                )}

                                {type === false ? (
                                    <Form.Group as={Col} md="12" controlId="validationFormikWalletNumber">
                                        <Form.Label>{t('walletNumber')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="wallet_number"
                                            value={values.wallet_number}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </Form.Group>
                                ) : (
                                    <>
                                        <Form.Group as={Col} md="12" controlId="validationFormikBankName">
                                            <Form.Label>{t('bankName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="bank_name"
                                                value={values.bank_name}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationFormikBankNumber">
                                            <Form.Label>{t('bankNumber')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="bank_number"
                                                value={values.bank_number}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationFormikFullName">
                                            <Form.Label>{t('accountHolder')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fullname"
                                                value={values.fullname}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Form.Group>
                                    </>
                                )}
                            </Row>
                            <Button type="submit" disabled={!isDispatch}>
                                {isDispatch === true ? (type === false ? t('rechargeUSDT') : t('withdrawVND')) : t('loading')}
                            </Button>
                            
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default Example;
